<template>
  <validation-observer ref="appraisalRules">
    <div>
      <b-row>
        <table class="table table-sm">
          <thead>
            <tr>
              <th scope="col">Vehicle Appraisal & Lien</th>
            </tr>
          </thead>
        </table>
      </b-row>

      <b-row>
        <b-col cols="12" md="7">
          <b-form-group>
            <label for="vehicle-type" class="border-bottom">Vehicle Title Type* </label>
            <validation-provider #default="{ errors }" name="Vehicle Title Type" :rules="wherefrom == 'quote-validation-edit' ? 'required' : ''">
              <b-form-radio-group class="border-bottom pb-1">
                <b-form-radio v-model="purchaseQuoteData.vehicleTitleType" name="vehicle-type" value="1">
                  Clean Owned
                </b-form-radio>
                <b-form-radio v-model="purchaseQuoteData.vehicleTitleType" name="vehicle-type" value="2">
                  Owned with Lien Payout
                </b-form-radio>
                <b-form-radio v-model="purchaseQuoteData.vehicleTitleType" name="vehicle-type" value="3">
                  Leased
                </b-form-radio>
              </b-form-radio-group>

              <small class="text-danger" v-if="purchaseQuoteData.vehicleTitleType == null && wherefrom == 'quote-validation-edit'">Vehicle Title Type is required </small>
              <small class="text-danger" v-else>{{ errors[0] }} </small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="3">
          <b-form-group>
            <label for="appraisedVehiclePrice">Appraised Vehicle Price*</label>
            <validation-provider #default="{ errors }" name="Appraised Vehicle Price" :rules="wherefrom == 'quote-validation-edit' ? 'required' : ''">
              <cleave id="appraisedVehiclePrice" v-model="purchaseQuoteData.appraisedVehiclePrice" class="form-control" :raw="true" :options="options.number" />
              <small class="text-danger" v-if="purchaseQuoteData.appraisedVehiclePrice == null && wherefrom == 'quote-validation-edit'">Appraised Vehicle Price is required </small>
              <small class="text-danger" v-else>{{ errors[0] }} </small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="12">
          <label for="appraisal-notes">Appraisal Notes</label>
          <b-form-textarea id="appraisal-notes" v-model="purchaseQuoteData.appraisalNotes" rows="3" />
        </b-col>
      </b-row>

      <b-row v-show="purchaseQuoteData.vehicleTitleType == 2 || purchaseQuoteData.vehicleTitleType == 3">
        <table class="table mt-1">
          <thead>
            <tr>
              <th scope="col">Lien Payout</th>
            </tr>
          </thead>
        </table>

        <b-col cols="12" md="7">
          <b-form-group>
            <label for="payout-type" class="border-bottom">Payout Type </label>
            <b-form-radio-group class="pb-1 border-bottom">
              <b-form-radio name="payout-type" v-model="purchaseQuoteData.payoutType" :disabled="purchaseQuoteData.vehicleTitleType == 3" class="mr-2" value="1">
                Finance Payout
              </b-form-radio>
              <b-form-radio name="payout-type" v-model="purchaseQuoteData.payoutType" :disabled="purchaseQuoteData.vehicleTitleType == 2" class="mr-2" value="2">
                Lease Payout
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6">
          <b-form-group>
            <label for="beforeTax">Net Payout Before Tax</label>
            <cleave id="beforeTax" placeholder="Option Price" v-model="purchaseQuoteData.beforeTax" class="form-control" :raw="true" :options="options.number" />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6">
          <b-form-group>
            <label for="unitlDate">Good Until Date</label>
            <flat-pickr id="quotationDate" class="form-control" :config="{ dateFormat: 'd-m-Y' }" placeholder="DD-MM-YYYY" v-model="purchaseQuoteData.unitlDate" />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="3" v-if="purchaseQuoteData.vehicleTitleType == 3">
          <b-form-group>
            <label for="gstRate">G.S.T Rate</label>
            <cleave id="gstRate" placeholder="Option Price" v-model="purchaseQuoteData.gstRate" class="form-control" :raw="true" :options="options.percent" />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="3" v-if="purchaseQuoteData.vehicleTitleType == 3">
          <b-form-group>
            <label for="gstAmount">G.S.T Amount</label>
            <cleave id="gstAmount" disabled placeholder="Option Price" v-model="purchaseQuoteData.gstAmount" class="form-control" :raw="true" :options="options.number" />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6" v-if="purchaseQuoteData.vehicleTitleType == 3">
          <b-form-group>
            <label for="totalAmount">Total Payout Amount</label>
            <cleave id="totalAmount" disabled placeholder="Option Price" v-model="purchaseQuoteData.totalAmount" class="form-control" :raw="true" :options="options.number" />
          </b-form-group>
        </b-col>
      </b-row>
    </div>
  </validation-observer>
</template>

<script>
import { BRow, BCol, BFormGroup, BFormRadio, BInputGroup, BFormInput, BFormRadioGroup, BFormTextarea } from 'bootstrap-vue';
import store from '@/store';
import VueNumericInput from 'vue-numeric-input';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import Cleave from 'vue-cleave-component';
import 'cleave.js/dist/addons/cleave-phone.us';
import flatPickr from 'vue-flatpickr-component';
import router from '@/router';
import { ValidationProvider, ValidationObserver } from 'vee-validate';

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormRadio,
    BInputGroup,
    BFormInput,
    BFormRadioGroup,
    BFormTextarea,

    //validation
    ValidationProvider,
    ValidationObserver,

    VueNumericInput,
    ToastificationContent,
    Cleave,
    flatPickr,
  },

  props: {
    purchaseQuoteData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      dropdownLoading: false,
      wherefrom: router.currentRoute.name,

      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },

        percent: {
          numeral: true,
          numeralPositiveOnly: true,
          blocks: [2],
          prefix: '% ',
          rawValueTrimPrefix: true,
          numeralIntegerScale: 2,
        },
      },
    };
  },

  watch: {
    'purchaseQuoteData.vehicleTitleType': {
      handler: function(id, before) {
        this.purchaseQuoteData.payoutType = null;
        if (this.purchaseQuoteData.vehicleTitleType == 2) {
          this.purchaseQuoteData.payoutType = 1;
        } else if (this.purchaseQuoteData.vehicleTitleType == 3) {
          this.purchaseQuoteData.payoutType = 2;
        } else {
          this.purchaseQuoteData.payoutType = null;
          this.purchaseQuoteData.beforeTax = null;
          this.purchaseQuoteData.unitlDate = null;
          this.purchaseQuoteData.gstRate = null;
        }
      },
    },

    'purchaseQuoteData.beforeTax': {
      handler: function(id, before) {
        if (this.purchaseQuoteData.gstRate != null || this.purchaseQuoteData.gstRate != '') {
          this.purchaseQuoteData.gstAmount = (this.purchaseQuoteData.beforeTax / 100) * this.purchaseQuoteData.gstRate;
          this.purchaseQuoteData.totalAmount = Number((this.purchaseQuoteData.beforeTax / 100) * this.purchaseQuoteData.gstRate) + Number(this.purchaseQuoteData.beforeTax);
          this.purchaseQuoteData.totalLienPayout = this.purchaseQuoteData.totalAmount;

          
        }
      },
    },

    'purchaseQuoteData.gstRate': {
      handler: function(id, before) {
        if (this.purchaseQuoteData.beforeTax != null || this.purchaseQuoteData.beforeTax != '') {
          this.purchaseQuoteData.gstAmount = (this.purchaseQuoteData.beforeTax / 100) * this.purchaseQuoteData.gstRate;
          this.purchaseQuoteData.totalAmount = Number((this.purchaseQuoteData.beforeTax / 100) * this.purchaseQuoteData.gstRate) + Number(this.purchaseQuoteData.beforeTax);
          this.purchaseQuoteData.totalLienPayout = this.purchaseQuoteData.totalAmount;

        }
      },
    },
  },

  computed: {},

  methods: {},
};
</script>
