<template>
  <validation-observer ref="lienRules">
    <b-row>
      <table class="table table-sm">
        <thead>
          <tr>
            <th scope="col">Lien Holder Identification</th>
          </tr>
        </thead>
      </table>
    </b-row>

    <b-row>
      <b-col lg="10" md="10" sm="12">
        <b-form-group label="Customer" label-for="customer">
          <v-select v-model="purchaseQuoteData.lienHolder.customerId" @input="lienCustomerSelect" :options="dropCustomers" :reduce="(val) => val.id" label="value" input-id="customer-data" :clearable="false"> </v-select>
        </b-form-group>
      </b-col>

      <b-col lg="2" md="2" sm="12" class="text-center" v-if="!purchaseQuoteData.lienHolder.customerId" v-show="$Can('customer_create')">
        <b-form-group class="mt-2" label-for="title">
          <b-button @click="customerEdit = !customerEdit" block variant="relief-dark" class="btn-icon">
            New Customer
          </b-button>
        </b-form-group>
      </b-col>

      <b-col lg="2" md="2" sm="12" class="text-center" v-if="purchaseQuoteData.lienHolder.customerId && purchaseQuoteData.id" v-show="$Can('customer_edit')">
        <b-form-group class="mt-2" label-for="title">
          <b-button @click="customerEdit = !customerEdit" block variant="relief-dark" class="btn-icon">
            Edit Customer
          </b-button>
        </b-form-group>
      </b-col>

      <b-col lg="2" md="2" sm="12" class="text-center" v-if="purchaseQuoteData.lienHolder.customerId && !purchaseQuoteData.id" v-show="$Can('customer_edit')">
        <b-form-group class="mt-2" label-for="title">
          <b-button @click="customerEdit = !customerEdit" block variant="relief-dark" class="btn-icon">
            Edit Customer
          </b-button>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row class="container mt-1" v-show="customerEdit" v-if="purchaseQuoteData.lienHolder.customerId">
      <table>
        <tr>
          <th class="pr-2">Seller Type:</th>
          <td>
            <span v-if="this.purchaseQuoteData.lienHolder.sellerType == 1"> Individual</span>
            <span v-else> Business</span>
          </td>
        </tr>

        <tr>
          <th class="pr-2">
            <strong v-if="this.purchaseQuoteData.lienHolder.sellerType == 1">First Name:</strong>
            <strong v-else>Business Name:</strong>
          </th>
          <td>
            <span> {{ this.purchaseQuoteData.lienHolder.firstName }}</span>
          </td>
        </tr>

        <tr v-if="this.purchaseQuoteData.lienHolder.sellerType == 2">
          <th class="pr-2">
            <strong class="mr-2">Contact Person:</strong>
          </th>
          <td>
            <span> {{ this.purchaseQuoteData.lienHolder.contactPerson }}</span>
          </td>
        </tr>

        <tr v-if="this.purchaseQuoteData.lienHolder.sellerType == 2">
          <th class="pr-2">
            <strong class="mr-2">Contact Info:</strong>
          </th>
          <td>
            <span> {{ this.purchaseQuoteData.lienHolder.contactInfo }}</span>
          </td>
        </tr>

        <tr>
          <th class="pr-2">
            <strong class="mr-2" v-if="this.purchaseQuoteData.lienHolder.sellerType == 1">Last Name:</strong>
            <strong class="mr-2" v-else>Operating Name:</strong>
          </th>
          <td>
            <span> {{ this.purchaseQuoteData.lienHolder.lastName }}</span>
          </td>
        </tr>

        <tr>
          <th class="pr-2">
            <strong class="mr-2">Driver's License Number:</strong>
          </th>
          <td>
            <span> {{ this.purchaseQuoteData.lienHolder.driverLicenseNumber }}</span>
          </td>
        </tr>

        <tr>
          <th class="pr-2">
            <strong class="mr-2">G.S.T Registration:</strong>
          </th>
          <td>
            <span> {{ this.purchaseQuoteData.lienHolder.gst }}</span>
          </td>
        </tr>

        <tr>
          <th class="pr-2">
            <strong class="mr-2">Phone Number:</strong>
          </th>
          <td>
            <span> {{ this.purchaseQuoteData.lienHolder.phoneNumber }}</span>
          </td>
        </tr>

        <tr>
          <th class="pr-2">
            <strong class="mr-2">Email:</strong>
          </th>
          <td>
            <span> {{ this.purchaseQuoteData.lienHolder.email }}</span>
          </td>
        </tr>

        <tr>
          <th class="pr-2">
            <strong class="mr-2">Other ID Number:</strong>
          </th>
          <td>
            <span> {{ this.purchaseQuoteData.lienHolder.otherIdNumber }}</span>
          </td>
        </tr>

        <tr>
          <th class="pr-2">
            <strong class="mr-2">P.S.T Registration:</strong>
          </th>
          <td>
            <span> {{ this.purchaseQuoteData.lienHolder.pst }}</span>
          </td>
        </tr>

        <tr>
          <th class="pr-2">
            <strong class="mr-2">Unit Number:</strong>
          </th>
          <td>
            <span> {{ this.purchaseQuoteData.lienHolder.unitNumber }}</span>
          </td>
        </tr>

        <tr>
          <th class="pr-2">
            <strong class="mr-2">Street Address:</strong>
          </th>
          <td>
            <span> {{ this.purchaseQuoteData.lienHolder.streetAddress }}</span>
          </td>
        </tr>

        <tr>
          <th class="pr-2">
            <strong class="mr-2">Date of Birth:</strong>
          </th>
          <td>
            <span> {{ this.purchaseQuoteData.lienHolder.dateOfBirth }}</span>
          </td>
        </tr>

        <tr>
          <th class="pr-2">
            <strong class="mr-2">Dealer Registration:</strong>
          </th>
          <td>
            <span> {{ this.purchaseQuoteData.lienHolder.dealer }}</span>
          </td>
        </tr>

        <tr>
          <th class="pr-2">
            <strong class="mr-2">City:</strong>
          </th>
          <td>
            <span> {{ this.purchaseQuoteData.lienHolder.city }}</span>
          </td>
        </tr>

        <tr>
          <th class="pr-2">
            <strong class="mr-2">Province:</strong>
          </th>
          <td>
            <span> {{ this.purchaseQuoteData.lienHolder.provence }}</span>
          </td>
        </tr>

        <tr>
          <th class="pr-2">
            <strong class="mr-2">Postal Code:</strong>
          </th>
          <td>
            <span> {{ this.purchaseQuoteData.lienHolder.postalCode }}</span>
          </td>
        </tr>

        <tr>
          <th class="pr-2">
            <strong class="mr-2">Country:</strong>
          </th>
          <td>
            <span> {{ this.purchaseQuoteData.lienHolder.country }}</span>
          </td>
        </tr>
      </table>
    </b-row>
    <!-- this.purchaseQuoteData.lienHolder -->
    <b-row class="container mt-1" v-show="!customerEdit">
      <b-col class="mt-1" cols="12" sm="6" md="4" xl="3">
        <b-form-group>
          <label for="some-radios" class="border-bottom">Seller Type </label>
          <validation-provider name="Seller Type" #default="{ errors }" rules="required">
            <b-form-radio-group class="border-bottom pb-1">
              <b-form-radio v-model="purchaseQuoteData.lienHolder.sellerType" :state="errors.length > 0 ? false : null" name="seller-type" class="mr-2" value="1">
                Individual
              </b-form-radio>
              <b-form-radio v-model="purchaseQuoteData.lienHolder.sellerType" :state="errors.length > 0 ? false : null" name="seller-type" class="mr-2" value="2">
                Business
              </b-form-radio>
            </b-form-radio-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col cols="12" sm="6" md="4" xl="3">
        <b-form-group>
          <validation-provider :name="purchaseQuoteData.lienHolder.sellerType == 2 ? 'Business Name' : 'First Name'" #default="{ errors }" rules="required">
            <label v-if="purchaseQuoteData.lienHolder.sellerType == 2" for="firstName">Business Name</label>
            <label v-else for="firstName">First Name</label>

            <b-form-input id="firstName" v-model="purchaseQuoteData.lienHolder.firstName" :state="errors.length > 0 ? false : null" type="text" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col cols="12" sm="6" md="4" xl="3">
        <b-form-group>
          <label v-if="purchaseQuoteData.lienHolder.sellerType == 2" for="lastName">Operating Name</label>
          <label v-else for="lastName">Last Name </label>
          <b-form-input id="lastName" v-model="purchaseQuoteData.lienHolder.lastName" type="text" />
        </b-form-group>
      </b-col>

      <b-col cols="12" sm="6" md="4" xl="3" v-if="purchaseQuoteData.lienHolder.sellerType == 2">
        <b-form-group>
          <label for="contactPerson">Contact Person</label>
          <b-form-input id="contactPerson" v-model="purchaseQuoteData.lienHolder.contactPerson" type="text" />
        </b-form-group>
      </b-col>

      <b-col cols="12" sm="6" md="4" xl="3" v-if="purchaseQuoteData.lienHolder.sellerType == 2">
        <b-form-group>
          <label for="contactInfo">Contact Info</label>
          <b-form-input id="contactInfo" v-model="purchaseQuoteData.lienHolder.contactInfo" type="text" />
        </b-form-group>
      </b-col>

      <b-col cols="12" sm="6" md="4" xl="3">
        <b-form-group>
          <label for="driverLicenseNumber">Driver's License Number</label>
          <b-form-input id="driverLicenseNumber" v-model="purchaseQuoteData.lienHolder.driverLicenseNumber" type="text" />
        </b-form-group>
      </b-col>

      <b-col cols="12" sm="6" md="4" xl="3">
        <b-form-group>
          <label for="gst">G.S.T Registration</label>
          <b-form-input id="gst" v-model="purchaseQuoteData.lienHolder.gst" type="text" />
        </b-form-group>
      </b-col>

      <b-col cols="12" sm="6" md="4" xl="3">
        <b-form-group>
          <label for="phoneNumber">Phone Number</label>
          <validation-provider #default="{ errors }" name="Phone" :rules="wherefrom == 'quote-validation-edit' ? 'required' : ''">
            <b-form-input id="phoneNumber" v-model="purchaseQuoteData.lienHolder.phoneNumber" type="text" />
            <small class="text-danger" v-if="purchaseQuoteData.lienHolder.phoneNumber == null && wherefrom == 'quote-validation-edit'">Phone Number is required </small>
            <small class="text-danger" v-else>{{ errors[0] }} </small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col cols="12" sm="6" md="4" xl="3">
        <b-form-group>
          <label for="email">Email</label>
          <validation-provider #default="{ errors }" name="Email" :rules="wherefrom == 'quote-validation-edit' ? 'required|email' : ''">
            <b-form-input id="email" v-model="purchaseQuoteData.lienHolder.email" type="text" />
            <small class="text-danger" v-if="purchaseQuoteData.lienHolder.email == null && wherefrom == 'quote-validation-edit'">Email is required </small>
            <small class="text-danger" v-else>{{ errors[0] }} </small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col cols="12" sm="6" md="4" xl="3">
        <b-form-group>
          <label for="otherIdNumber">Other ID Number</label>
          <b-form-input id="otherIdNumber" v-model="purchaseQuoteData.lienHolder.otherIdNumber" type="text" />
        </b-form-group>
      </b-col>

      <b-col cols="12" sm="6" md="4" xl="3">
        <b-form-group>
          <label for="pst">P.S.T Registration</label>
          <b-form-input id="pst" v-model="purchaseQuoteData.lienHolder.pst" type="text" />
        </b-form-group>
      </b-col>

      <b-col cols="12" sm="6" md="4" xl="3">
        <b-form-group>
          <label for="unitNumber">Unit Number</label>
          <b-form-input id="unitNumber" v-model="purchaseQuoteData.lienHolder.unitNumber" type="text" />
        </b-form-group>
      </b-col>

      <b-col cols="12" sm="6" md="4" xl="6">
        <b-form-group>
          <label for="streetAddress">Street Address</label>
          <validation-provider #default="{ errors }" name="Street Address" :rules="wherefrom == 'quote-validation-edit' ? 'required' : ''">
            <b-form-input id="streetAddress" v-model="purchaseQuoteData.lienHolder.streetAddress" type="text" />
            <small class="text-danger" v-if="purchaseQuoteData.sellerIdentification.email == null && wherefrom == 'quote-validation-edit'">Street Address is required </small>
            <small class="text-danger" v-else>{{ errors[0] }} </small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col cols="12" sm="6" md="4" xl="3">
        <b-form-group>
          <label for="dateOfBirth">Date of Birth</label>
          <flat-pickr id="dateOfBirth" class="form-control" :config="{ dateFormat: 'd-m-Y' }" placeholder="DD-MM-YYYY" v-model="purchaseQuoteData.lienHolder.dateOfBirth" />
        </b-form-group>
      </b-col>

      <b-col cols="12" sm="6" md="4" xl="3">
        <b-form-group>
          <label for="dealer">Dealer Registration</label>
          <b-form-input id="dealer" v-model="purchaseQuoteData.lienHolder.dealer" type="text" />
        </b-form-group>
      </b-col>

      <b-col cols="12" sm="6" md="4" xl="3">
        <b-form-group>
          <label for="city">City</label>
          <validation-provider #default="{ errors }" name="City" :rules="wherefrom == 'quote-validation-edit' ? 'required' : ''">
            <b-form-input id="city" v-model="purchaseQuoteData.lienHolder.city" type="text" />
            <small class="text-danger" v-if="purchaseQuoteData.lienHolder.city == null && wherefrom == 'quote-validation-edit'">City is required </small>
            <small class="text-danger" v-else>{{ errors[0] }} </small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col cols="12" sm="6" md="4" xl="3">
        <b-form-group>
          <label for="provence">Province</label>
          <validation-provider #default="{ errors }" name="Province" :rules="wherefrom == 'quote-validation-edit' ? 'required' : ''">
            <b-form-input id="provence" v-model="purchaseQuoteData.lienHolder.provence" type="text" />
            <small class="text-danger" v-if="purchaseQuoteData.lienHolder.provence == null && wherefrom == 'quote-validation-edit'">Province is required </small>
            <small class="text-danger" v-else>{{ errors[0] }} </small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col cols="12" sm="6" md="4" xl="3">
        <b-form-group>
          <label for="postalCode">Postal Code</label>
          <validation-provider #default="{ errors }" name="Postal Code" :rules="wherefrom == 'quote-validation-edit' ? 'required' : ''">
            <b-form-input id="postalCode" v-model="purchaseQuoteData.lienHolder.postalCode" type="text" />
            <small class="text-danger" v-if="purchaseQuoteData.lienHolder.postalCode == null && wherefrom == 'quote-validation-edit'">Postal Code is required </small>
            <small class="text-danger" v-else>{{ errors[0] }} </small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col cols="12" sm="6" md="4" xl="3">
        <b-form-group>
          <label for="country">Country</label>
          <validation-provider #default="{ errors }" name="Country" :rules="wherefrom == 'quote-validation-edit' ? 'required' : ''">
            <b-form-input id="country" v-model="purchaseQuoteData.lienHolder.country" type="text" />
            <small class="text-danger" v-if="purchaseQuoteData.lienHolder.country == null && wherefrom == 'quote-validation-edit'">Country is required </small>
            <small class="text-danger" v-else>{{ errors[0] }} </small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
import { BRow, BCol, BFormGroup, BFormRadio, BInputGroup, BFormInput, BFormRadioGroup } from 'bootstrap-vue';
import store from '@/store';
import vSelect from 'vue-select';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import Cleave from 'vue-cleave-component';
import 'cleave.js/dist/addons/cleave-phone.us';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import flatPickr from 'vue-flatpickr-component';
import router from '@/router';

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormRadio,
    BInputGroup,
    BFormInput,
    BFormRadioGroup,

    ToastificationContent,
    Cleave,
    vSelect,
    flatPickr,

    ValidationProvider,
    ValidationObserver,
  },

  props: {
    purchaseQuoteData: {
      type: Object,
      required: true,
    },
    dropCustomers: {
      type: Array,
      required: true,
    },
    lienCustomerData: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      dropdownLoading: false,
      wherefrom: router.currentRoute.name,
      customerEdit: true,
    };
  },

  watch: {
    lienCustomerData: function(val) {
      if (val != null) {
        this.purchaseQuoteData.lienHolder.sellerType = val.sellerType;
        this.purchaseQuoteData.lienHolder.phoneNumber = val.phoneNumber;
        this.purchaseQuoteData.lienHolder.firstName = val.firstName;
        this.purchaseQuoteData.lienHolder.lastName = val.lastName;
        this.purchaseQuoteData.lienHolder.email = val.email;
        this.purchaseQuoteData.lienHolder.unitNumber = val.unitNumber;
        this.purchaseQuoteData.lienHolder.provence = val.provence;
        this.purchaseQuoteData.lienHolder.streetAddress = val.streetAddress;
        this.purchaseQuoteData.lienHolder.city = val.city;
        this.purchaseQuoteData.lienHolder.postalCode = val.postalCode;
        this.purchaseQuoteData.lienHolder.country = val.country;
        this.purchaseQuoteData.lienHolder.gst = val.gst;
        this.purchaseQuoteData.lienHolder.pst = val.pst;
        this.purchaseQuoteData.lienHolder.dealer = val.dealer;
        this.purchaseQuoteData.lienHolder.driverLicenseNumber = val.driverLicenseNumber;
        this.purchaseQuoteData.lienHolder.otherIdNumber = val.otherIdNumber;
        this.purchaseQuoteData.lienHolder.dateOfBirth = val.dateOfBirth;
        this.purchaseQuoteData.lienHolder.customerId = val.id;
        this.purchaseQuoteData.lienHolder.contactPerson = val.contactPerson;
        this.purchaseQuoteData.lienHolder.contactInfo = val.contactInfo;
      }
    },
  },

  created() {},

  computed: {},

  methods: {
    lienCustomerSelect(id) {
      this.customerEdit = true;
      if (id != null) {
        store
          .dispatch('purchase/getCustomerDropDownId', id)
          .then((response) => {
            this.purchaseQuoteData.lienHolder.sellerType = response.data.sellerType;
            this.purchaseQuoteData.lienHolder.phoneNumber = response.data.phoneNumber;
            this.purchaseQuoteData.lienHolder.firstName = response.data.firstName;
            this.purchaseQuoteData.lienHolder.lastName = response.data.lastName;
            this.purchaseQuoteData.lienHolder.email = response.data.email;

            this.purchaseQuoteData.lienHolder.unitNumber = response.data.unitNumber;
            this.purchaseQuoteData.lienHolder.provence = response.data.provence;
            this.purchaseQuoteData.lienHolder.streetAddress = response.data.streetAddress;
            this.purchaseQuoteData.lienHolder.city = response.data.city;
            this.purchaseQuoteData.lienHolder.postalCode = response.data.postalCode;
            this.purchaseQuoteData.lienHolder.country = response.data.country;
            this.purchaseQuoteData.lienHolder.gst = response.data.gst;
            this.purchaseQuoteData.lienHolder.pst = response.data.pst;
            this.purchaseQuoteData.lienHolder.dealer = response.data.dealer;

            this.purchaseQuoteData.lienHolder.driverLicenseNumber = response.data.driverLicenseNumber;
            this.purchaseQuoteData.lienHolder.otherIdNumber = response.data.otherIdNumber;
            this.purchaseQuoteData.lienHolder.dateOfBirth = response.data.dateOfBirth;
            this.purchaseQuoteData.lienHolder.contactPerson = response.data.contactPerson;
            this.purchaseQuoteData.lienHolder.contactInfo = response.data.contactInfo;

            this.purchaseQuoteData.lienHolder.customerId = response.data.id;
          })
          .catch((error) => {});
      } else {
        this.purchaseQuoteData.lienHolder.sellerType = null;
        this.purchaseQuoteData.lienHolder.phoneNumber = null;
        this.purchaseQuoteData.lienHolder.firstName = null;
        this.purchaseQuoteData.lienHolder.lastName = null;
        this.purchaseQuoteData.lienHolder.email = null;

        this.purchaseQuoteData.lienHolder.unitNumber = null;
        this.purchaseQuoteData.lienHolder.provence = null;
        this.purchaseQuoteData.lienHolder.streetAddress = null;
        this.purchaseQuoteData.lienHolder.city = null;
        this.purchaseQuoteData.lienHolder.postalCode = null;
        this.purchaseQuoteData.lienHolder.country = null;
        this.purchaseQuoteData.lienHolder.gst = null;
        this.purchaseQuoteData.lienHolder.pst = null;
        this.purchaseQuoteData.lienHolder.dealer = null;

        this.purchaseQuoteData.lienHolder.driverLicenseNumber = null;
        this.purchaseQuoteData.lienHolder.otherIdNumber = null;
        this.purchaseQuoteData.lienHolder.dateOfBirth = null;
        this.purchaseQuoteData.lienHolder.customerId = null;
        this.purchaseQuoteData.lienHolder.contactPerson = null;
        this.purchaseQuoteData.lienHolder.contactInfo = null;
      }
    },
  },
};
</script>
